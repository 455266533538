export enum ComponentsToLoad {
    AccountSummary = 'AccountSummary',
    Contracts = 'Contracts',
    ItemsOut = 'ItemsOut',
    Tracking = 'Tracking',
    Invoices = 'Invoices',
    Logout = 'Logout',
    ConsumerPortal = 'ConsumerPortal',
    Subdomain = 'Subdomain',
    AdminOverview = 'AdminOverview',
    AdminDifference = 'AdminDifference',
    AdminSetup = 'AdminSetup',
    AdminWebPay = 'AdminWebpay',
    AdminAccountList = 'AdminAccountList',
    BasicConsumerPortal = 'BasicConsumerPortal',
    QuickLink = 'QuickLink',
    ConsumerPortalError = 'ConsumerPortalError',
    RoutingError = 'RoutingError',
    Payment = 'Payment'
}

export enum SubComponentsToLoad {
    ContractDetail = 'ContractDetail',
    ProductDetail = 'ProductDetail',
    Default = 'Default'
}
