import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'por-base-select',
    templateUrl: './base-select.component.html',
    styleUrls: ['./base-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseSelectComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseSelectComponent implements OnInit, ControlValueAccessor, OnChanges {
    disabled = false;

    @Input()
    public parentForm!: FormGroup;

    @Input()
    public formControlName!: string;

    @Output() readonly modelChange = new EventEmitter<string | number>();
    @Input() label: unknown;
    @Input() required = false;
    @Input() disabledState = false;
    @Input() model!: string | number;
    @Input() options!: SelectOptions[];
    @Input() featureName = '';
    @Input() selectStyle = '';
    public value!: string | number;
    public changed!: (value: string) => void;
    public touched!: () => void;
    public isDisabled!: boolean;

    ngOnInit(): void {
        this.value = this.model;
    }

    ngOnChanges(_changes: { disabledState?: { currentValue: boolean } }) {
        if (_changes.disabledState !== undefined) {
            this.disabled = _changes.disabledState.currentValue;
            if (this.parentForm) {
                _changes.disabledState.currentValue ? this.parentForm.disable() : this.parentForm.enable();
            }
        }
    }

    writeValue(value: string): void {
        if (this.model !== '') {
            this.value = this.model;
            return;
        }
        this.value = value;
    }

    registerOnChange(fn: () => void): void {
        this.changed = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled;
    }

    get formField(): FormControl {
        return this.parentForm?.get(this.formControlName) as FormControl;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateNewValue(_newValue?: string) {
        this.modelChange.emit(this.model);
    }
}

export interface SelectOptions {
    label: string;
    value: string;
}
