import { Component, OnDestroy, ElementRef, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ModalService } from '../../../services/modal.service';
import { LoggerService } from '../../../services/logger.service';

@Component({
    selector: 'por-base-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input() id: string | undefined;
    private readonly element: HTMLElement;
    @Output() readonly onclose = new EventEmitter<boolean>(false);
    @Output() readonly onopen = new EventEmitter<boolean>(false);
    @Input() modalWidth: 'modal-lg' | 'modal-md' | 'modal-xl' = 'modal-md';

    constructor(private readonly modalService: ModalService, private readonly el: ElementRef, private readonly logger: LoggerService) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            this.logger.logError('modal must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', (event: MouseEvent) => {
            if ((event.target as Element).className === 'jw-modal') {
                this.close();
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        if (this.id) {
            this.modalService.remove(this.id);
            this.element.remove();
        }
    }

    // open modal
    open(): void {
        this.element.style.display = 'block';
        this.element.style.overflow = 'hidden auto';
        this.element.style.opacity = '1';
        document.body.classList.add('jw-modal-open');

        /**
         * Apx Id have been set, just because to apply apx css to modals inputs elements.
         * Modal have been appeneded to body
         */
        document.body.setAttribute('id', 'apx');
        this.onopen.emit(true);
    }

    // close modal
    close(): void {
        if (this.element) {
            this.element.style.display = 'none';
        }
        document.body.classList.remove('jw-modal-open');
        document.body.removeAttribute('id');
        this.onclose.emit(true);
    }
}
