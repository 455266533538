import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ItemOutCP } from '../../../../models/item-out-model';
import { trimName } from '../../../../utils/trimName-utils';

@Component({
    selector: 'por-items-out-detail-renderer',
    templateUrl: './items-out-detail-renderer.component.html',
    styleUrls: ['./items-out-detail-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsOutDetailRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    cellValue!: string;

    public params!: ICellRendererParams;
    private itemOut!: ItemOutCP;
    constructor(private readonly cdref: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.cdref.detectChanges();
    }
    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.cellValue = this.getValueToDisplay(params);
        this.params = params;
        this.itemOut = params.data;
    }

    // gets called whenever the user gets the cell to refresh
    refresh(params: ICellRendererParams) {
        // set value into cell again
        this.cellValue = this.getValueToDisplay(params);
        return true;
    }

    loadProductDetail() {
        this.params.context?.componentParent.loadProductDetail(this.itemOut);
    }

    getValueToDisplay(params: ICellRendererParams) {
        const value = params.valueFormatted ? params.valueFormatted : params.value;
        return trimName(value);
    }
}
