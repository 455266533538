import { setLoading } from '../actions/loder.actions';
import { createReducer, on } from '@ngrx/store';

export interface Loading {
    isLoading: boolean;
}

export const initialLoadingState: Loading = {
    isLoading: false
};

export const loaderReducer = createReducer(
    initialLoadingState,
    on(setLoading, (state, { isLoading }) => {
        return { ...state, isLoading };
    })
);
