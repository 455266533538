import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, LOCALE_ID } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { ItemOutCP } from '../../models/item-out-model';
import { RowLabelValue } from '../../models/row-label-value-model';
import { LineItemStatus } from '../../enums/line-item-status.enum';
import { AppFacadeService } from '../../services/app-facade.service';
@Component({
    selector: 'por-items-out-full-width-row',
    templateUrl: './items-out-full-width-row.component.html',
    styleUrls: ['./items-out-full-width-row.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ItemsOutFullWidthRowComponent implements ICellRendererAngularComp {
    public params!: ICellRendererParams;
    checkboxSelected = false;
    isDisabled = false;
    constructor(@Inject(LOCALE_ID) locale: string, private readonly date: DatePipe, readonly appFacadeService: AppFacadeService) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    itemOut!: ItemOutCP;
    itemOutKV: RowLabelValue[] = [];
    dateFormat = '';
    agInit(params: ICellRendererParams): void {
        this.itemOut = { ...params.data };
        this.params = params;
        this.isDisabled = params.data && params.data.lineItemStatus === LineItemStatus.Out ? false : true;
        this.itemOutKV = [
            {
                label: 'itemName',
                value: params.data?.itemName
            },
            {
                label: 'contract',
                value: params.data?.contractId
            },
            {
                label: 'Quantity',
                value: params.data?.quantity
            },
            {
                label: 'startDate',
                value: this.date.transform(params.data?.startDate, this.dateFormat)
            },
            {
                label: 'dueDate',
                value: this.date.transform(params.data?.dueDate, this.dateFormat)
            },
            {
                label: 'purchaseOrder',
                value: params.data?.purchaseOrder
            },
            {
                label: 'location',
                value: params.data?.info
            }
        ];
    }
    refresh() {
        return true;
    }

    loadRequestmodal() {
        this.params.context?.componentParent.loadRequestmodal(this.itemOut);
    }

    checkedHandler(check: boolean) {
        this.params.context.componentParent.setMobileCheckboxandItems(check, this.itemOut);
    }
}
