/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Note: Generic interface
 */
import { ApiResultMessageType } from '../enums';

export interface ApiResultMessage {
    Type: ApiResultMessageType;
    Token?: string;
    TokenData?: { [param: string]: unknown };
    Message?: string;
    StackTrace?: string;
    Status?: number;
}

export interface ApiResult<T> {
    Message: ApiResultMessage;
    Record?: T;
    Records?: T[];
}
