import { createReducer, on } from '@ngrx/store';
import { setConsumerPortalState } from '../actions/view-state.actions';
import { ConsumerPortalState } from '../../types/state.type';

export interface ConsumerPortalStates {
    consumerPortalState: ConsumerPortalState;
}

export const initialConsumerPortalState: ConsumerPortalStates = {
    consumerPortalState: 'None'
};

export const loadstateReducer = createReducer(
    initialConsumerPortalState,
    on(setConsumerPortalState, (state, { consumerPortalState }) => {
        return {
            ...state,
            consumerPortalState
        };
    })
);
