import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownOption, dropdownOptions } from '../../models/dropdown-option.model';
import { AppFacadeService } from '../../../services/app-facade.service';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'por-load-more',
    templateUrl: './load-more.component.html',
    styleUrls: ['./load-more.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LoadMoreComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadMoreComponent implements OnInit, OnDestroy {
    dropdownOptions: DropdownOption[] = dropdownOptions;
    @Input() hasMoreRecord = false;
    @Input() featureName = '';
    @Output() readonly loadMore = new EventEmitter<number>();
    @Input() totalRowCount = 0;
    @Input() displayedRowCount = 0;

    selectedValue!: string | number;
    previousValue!: string | number;
    selectedValue$: Observable<{ selectedPageSize: string | number; previousPageSize: string | number }>;
    private readonly subscription: Subscription = new Subscription();

    constructor(private readonly appFacadeService: AppFacadeService) {
        this.selectedValue$ = this.appFacadeService.getPageSizeValues();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.appFacadeService.getPageSizeValues().subscribe(values => {
                this.selectedValue = values.selectedPageSize.toString() || this.dropdownOptions[0]?.value;
                this.previousValue = values.previousPageSize;
            })
        );

        if (!this.selectedValue && this.dropdownOptions.length > 0) {
            this.selectedValue = this.dropdownOptions[0]?.value;
            this.appFacadeService.setPageSizeValue(this.selectedValue);
        }
    }

    onValueChange(newValue: string | number): void {
        if (typeof newValue === 'string' || typeof newValue === 'number') {
            this.selectedValue = newValue;
        }
    }

    get showLoadMoreButton(): boolean {
        return this.totalRowCount > this.displayedRowCount;
    }

    onLoadMore(): void {
        this.appFacadeService.setPageSizeValue(this.selectedValue);
        this.displayedRowCount += Number(this.selectedValue);
        this.loadMore.emit(Number(this.selectedValue));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
