<div [ngClass]="selectStyle" class="base-select-wrapper">
    <mat-form-field appearance="outline" [class.infix-nolabel]="!label">
        <mat-label *ngIf="!!label">{{ label }}</mat-label>
        <mat-select [disabled]="disabled" [required]="required" [(ngModel)]="model" (ngModelChange)="updateNewValue($event)" porAddFeature [featureName]="featureName">
            <mat-option *ngFor="let option of options" [value]="option.value">
                {{ option.label }}
            </mat-option>
        </mat-select>
        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        <mat-error *ngIf="formField?.hasError('required')">required*</mat-error>
    </mat-form-field>
</div>
