import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Component, Injectable, Input, forwardRef, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'por-base-radio',
    templateUrl: './base-radio.component.html',
    styleUrls: ['./base-radio.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => BaseRadioComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Injectable()
export class BaseRadioComponent implements ControlValueAccessor {
    @Input() model?: string | null;
    public value = '';
    public changed!: (value: string) => void;
    public touched!: () => void;
    public isDisabled!: boolean;
    @Output() readonly modelChange = new EventEmitter<string | null>();
    @Input() required = false;
    @Input() featureName = '';
    @Input() style = '';
    writeValue(value: string): void {
        if (this.model !== '') {
            this.value = this.model ? this.model : '';
            return;
        }
        this.value = value;
    }

    registerOnChange(fn: () => void): void {
        this.changed = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.touched = fn;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Input() values: OptionRadio[] = [];

    onChange(event: { value: string }): void {
        const value: string = event.value;
        this.changed(value);
    }

    updateNewValue(event?: { value: string }): void {
        if (event?.value) {
            this.model = event.value;
            this.onChange(event);
            this.modelChange.emit(this.model);
            this.value = this.model;
        }
    }
}

export interface OptionRadio {
    text: string;
    value: unknown;
}
