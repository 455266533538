<div class="dialog-backdrop" id="apx">
    <div class="dialog-box">
        <div class="dialog-content">
            <p class="error-message">{{ data.errormessage }}</p>
            <button *ngIf="!allowClose" (click)="onRetryClick()" apxButton>{{ 'Retry' | translate }}</button>

            <div class="error-details">
                <h3 class="text-align-center">
                    <strong>{{ 'ErrorDetails' | translate }}</strong>
                </h3>
                <p>
                    <strong>{{ 'ErrorStatusCode' | translate }}:</strong>
                    {{ data.errorStatus }}
                </p>
                <p *ngIf="data.orgId">
                    <strong>{{ 'organizationId' | translate }}:</strong>
                    {{ data.orgId }}
                </p>
                <p *ngIf="data.contractId">
                    <strong>{{ 'contractId' | translate }}:</strong>
                    {{ data.contractId }}
                </p>
                <p *ngIf="data.customerId">
                    <strong>{{ 'customerId' | translate }}:</strong>
                    {{ data.customerId }}
                </p>
                <p *ngIf="data.quickLink">
                    <strong>{{ 'QuickLink' | translate }}:</strong>
                    {{ data.quickLink }}
                </p>
                <p>
                    <strong>{{ 'Date' | translate }}:</strong>
                    {{ data.date }}
                </p>
                <p *ngIf="data.apiMessage" class="grey">
                    <strong>{{ 'ErrorMessage' | translate }}:</strong>
                    {{ data.apiMessage }}
                </p>
            </div>

            <button *ngIf="allowClose" (click)="onCloseClick()" apxButton>{{ 'Close' | translate }}</button>
        </div>
    </div>
</div>
