import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DateUtility } from '../../../date.utility';
import { DateFormatType } from '../../../enums/date-format-type.enum';
import { FormBuilder, Validators } from '@angular/forms';
import { AppFormValidators } from '../../../admin/validators/app.validators';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, take, tap } from 'rxjs';
import { Customer } from '../../../models/consumer';
import { Store, select } from '@ngrx/store';
import { getCustomer } from '../../../store/selector/customer.selectors';
import { ContractDetail, LineItems } from '../../../models/contract-model';
import { AuthUiService } from '../../../services/auth-ui.service';
import { NotificationService } from '../../../services';
import { ContractService } from '../../../services/contract.service';
import { CloneContract, ItemsData } from '../../../models/clone-contract.model';
import { DatePipe } from '@angular/common';
import { AppFacadeService } from '../../../services/app-facade.service';
import { AppMediatorService } from '../../../services/app-mediator.service';
import { NgxMatTimepickerComponent } from 'ngx-mat-timepicker';
import { DialogContentType } from '../../../enums/dialog-type.enum';
import { LOAD_MORE_DROP_DOWN_DEFAULT_VALUE } from '../../../constants/default.const';
import { ItemOutService } from '../../../services/item-out.service';

@Component({
    selector: 'por-book-again-contract-request-form',
    templateUrl: './book-again-contract-request-form.component.html',
    styleUrls: ['./book-again-contract-request-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookAgainContractRequestFormComponent implements OnInit, OnDestroy {
    dateFormat: string;
    isStepValid = false;
    minDate = new Date(); // Set it to the current date to disable past dates.
    displayedRowCount = LOAD_MORE_DROP_DOWN_DEFAULT_VALUE;
    @Input() uiUrl: string | undefined;
    currentTime!: string;
    @Output()
    readonly isFormReturn: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    readonly isFormSubmittedSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
    cloneContractForm = this.fb.group({
        customerName: ['', [Validators.required, Validators.maxLength(255)]],
        customerPhone: ['', [Validators.required, Validators.maxLength(30)]],
        email: ['', [Validators.required, AppFormValidators.emailValidator, Validators.maxLength(255)]],
        startDate: ['', [Validators.required, AppFormValidators.futureDateValidator]],
        startTime: ['', [Validators.required]],
        modifications: ['', [Validators.nullValidator]]
    });

    customer$!: Observable<Customer>;
    @Input() customerId!: string;
    subscriptions: Subscription[] = [];
    customerInfo!: Customer | null;
    @Input() contractDetail!: ContractDetail[] | null;
    @ViewChild('timepicker') timepicker: NgxMatTimepickerComponent | undefined;
    constructor(
        @Inject(LOCALE_ID) locale: string,
        private readonly appMediatorService: AppMediatorService,
        private readonly translateService: TranslateService,
        private readonly store: Store,
        private readonly auth: AuthUiService,
        private readonly appFacadeService: AppFacadeService,
        private readonly notification: NotificationService,
        private readonly fb: FormBuilder,
        private readonly contractService: ContractService,
        private readonly datePipe: DatePipe,
        readonly itemOutService: ItemOutService
    ) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    ngOnInit(): void {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);

        this.cloneContractForm.setValue({
            customerName: '',
            customerPhone: '',
            email: '',
            startDate: '',
            startTime: '',
            modifications: ''
        });
        this.customer$ = this.store.pipe(select(getCustomer));

        this.subscriptions.push(
            this.customer$
                .pipe(
                    take(1),
                    tap(customer => {
                        this.customerInfo = customer;
                    })
                )
                .subscribe()
        );
        this.currentTime = this.formatTime(new Date());
    }

    ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }

    get customerEmail() {
        return this.auth.user.Email;
    }

    get customerName() {
        return this.cloneContractForm.get('customerName');
    }

    get customerPhone() {
        return this.cloneContractForm.get('customerPhone');
    }

    get email() {
        return this.cloneContractForm.get('email');
    }

    get startDate() {
        return this.cloneContractForm.get('startDate');
    }

    get startTime() {
        return this.cloneContractForm.get('startTime');
    }

    get modifications() {
        return this.cloneContractForm.get('modifications');
    }

    return() {
        this.isFormReturn.emit(true);
    }

    isValidContractDateTime() {
        if (this.startDate?.valid && this.startTime?.valid) {
            return true;
        }
        return false;
    }

    sendCloneContractForm() {
        this.appFacadeService.setLoading(true);
        if (!this.cloneContractForm.valid) {
            this.notification.error(this.translateService.instant('pleaseFillRequired'));
            this.appFacadeService.setLoading(false);
            return false;
        }

        if (!this.customerId) {
            return;
        }
        const itemsData: ItemsData[] | undefined = this.contractDetail?.[0].LineItems.map((item: LineItems) => {
            return {
                itemId: this.itemOutService.getLineItemKeyName(item),
                itemName: item?.Name,
                quantity: item?.Quantity
            };
        });
        let startDate = '',
            startTime = '';

        if (this.cloneContractForm.value?.startDate) {
            startDate = this.datePipe.transform(this.cloneContractForm.value?.startDate, this.dateFormat) as string;
        }
        if (this.cloneContractForm.value?.startTime) {
            const timeString = this.cloneContractForm.value.startTime;
            const [hours, minutes] = timeString.split(':').map(Number);

            // Create a new Date object and set the hours and minutes
            const time = new Date();
            time.setHours(hours, minutes);

            startTime = this.datePipe.transform(time, 'HH:mm') as string;
        }
        const data: CloneContract = {
            items: itemsData,
            contractId: this.contractDetail?.[0]?.Id,
            customerId: this.customerId,
            accountName: this.customerInfo?.CompanyName ?? this.customerInfo?.Name,
            ...this.cloneContractForm.value,
            startTime,
            startDate
        };
        this.subscriptions.push(
            this.contractService.cloneContract(data).subscribe({
                next: () => {
                    this.appFacadeService.setLoading(false);
                    this.cloneContractForm.reset();
                    this.isFormSubmittedSuccess.emit(true);
                    this.appFacadeService.openDialog(DialogContentType.RequestSentSuccessfully);
                },
                error: () => {
                    this.appFacadeService.setLoading(false);
                    this.notification.error(this.translateService.instant('somethingWentWrong'));
                }
            })
        );
        return;
    }

    showLoadMoreButton() {
        return this.contractDetail?.[0]?.LineItems?.length !== undefined && this.contractDetail?.[0]?.LineItems?.length > this.displayedRowCount;
    }

    openTimePicker(): void {
        if (this.timepicker) {
            this.timepicker.open();
        }
    }

    formatTime(date: Date): string {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    handleLoadMore(count: number) {
        this.displayedRowCount += count;
    }
}
