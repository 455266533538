import { Component, Input, LOCALE_ID, Inject, Output, EventEmitter, OnInit, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { DateUtility } from '../../date.utility';
import { DateFormatType } from '../../enums/date-format-type.enum';
import { RequestActionsToLoad } from '../../enums/request-actions.enum';
import { ItemOutCP } from '../../models/item-out-model';
import { RequestActionService } from '../../services/request-action.service';
import { CallOffRequest } from '../../models/calloff-request.model';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { ReturnRequestType } from '../../enums/return-request-type.enum';
import { sumOfArray } from '../../utils/sumOfArray.util';

@Component({
    selector: 'por-rental-request-modal',
    templateUrl: './rental-request-modal.component.html',
    styleUrls: ['./rental-request-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RentalRequestModalComponent implements OnInit, OnChanges {
    @Input() items!: ItemOutCP[];
    @Input() customerId!: string;
    dateFormat: string;
    @Input() totalItems = 0;

    @Input() uiUrl: string | undefined;

    @Output()
    readonly callOffRequestInfo: EventEmitter<CallOffRequest> = new EventEmitter<CallOffRequest>();
    enabledTab$ = this.requestActionService.activeTab$;

    constructor(@Inject(LOCALE_ID) locale: string, private readonly requestActionService: RequestActionService, readonly featureToggleService: FeatureToggleService) {
        this.dateFormat = DateUtility.getDateDisplayFormat(DateFormatType.StandardDate, locale);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items']) {
            /**
             * For multiple selected line items, not to list the items that are already called-off on the Call-Off form.
             */
            if (this.items.length > 1) {
                this.items = this.items.filter(item => {
                    if (item?.isBulk) {
                        return item?.callOffQty && Array.isArray(item?.callOffQty) && sumOfArray(item?.callOffQty) < item.quantity;
                    } else {
                        return item?.requestType !== ReturnRequestType.Return;
                    }
                });
            }
            this.totalItems = this.items.length;
        }
    }

    ngOnInit(): void {
        /**
         * Reset default view whenever rental request modal opens
         */
        this.handleRentalFormReturn();
    }

    renderRequestReturn() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.RequestReturnView);
    }

    renderRequestService() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.RequestServiceView);
    }

    handleRentalFormSubmission($event: CallOffRequest) {
        this.callOffRequestInfo.emit($event);
    }

    handleRentalFormReturn() {
        this.requestActionService.setActiveTab(RequestActionsToLoad.DefaultView);
    }

    isReturnRequestsAvailable(): boolean {
        return this.featureToggleService.isAvailable('callOffAbility') || this.featureToggleService.isAvailable('requestService');
    }

    isReturnServiceAvailable(): boolean {
        return !this.items.every(item => item?.requestType === ReturnRequestType.Return);
    }
}
