<div [ngClass]="{ 'confirm-dialog': !externalClass || !externalClass.length, externalClass: externalClass && externalClass.length }">
    <div class="dialog-content">
        {{ message }}
        <ng-content></ng-content>
    </div>
    <ng-container [ngSwitch]="dialogType">
        <ng-container *ngSwitchCase="dialogTypeEnum.confirm">
            <div class="dialog-actions">
                <por-base-button (onclick)="sendPrompt(false)">
                    <span>{{ 'no' | translate }}</span>
                </por-base-button>
                <por-base-button (onclick)="sendPrompt(true)">
                    <span>{{ 'yes' | translate }}</span>
                </por-base-button>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div class="dialog-actions">
                <por-base-button (onclick)="sendPrompt(false)">
                    <span>{{ 'ok' | translate }}</span>
                </por-base-button>
            </div>
        </ng-container>
    </ng-container>
</div>
