import { createAction, props } from '@ngrx/store';
import { ComponentsToLoad, SubComponentsToLoad } from '../../enums/components-to-load.enum';

export enum ActiveTabActionTypes {
    SetActiveTab = '[ComponentToLoad] Set Active Tab',
    SetSubActiveTab = '[ComponentToLoad] Set Sub Active Tab'
}
export const setActiveTab = createAction(ActiveTabActionTypes.SetActiveTab, props<{ activeTab: ComponentsToLoad }>());

export const setSubActiveTab = createAction(ActiveTabActionTypes.SetSubActiveTab, props<{ subActiveTab: SubComponentsToLoad }>());
