import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogType } from '../../../enums/dialog-type.enum';

@Component({
    selector: 'por-app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
    constructor(private readonly translate: TranslateService, private readonly renderer2: Renderer2, @Inject(DOCUMENT) private readonly document: Document) {}

    dialogTypeEnum = DialogType;
    @Input() dialogType: DialogType = DialogType.information;
    @Input() message: string = this.translate.instant('Are you sure?');
    @Input() externalClass?: string;
    @Output() readonly confirmed = new EventEmitter<boolean>();

    ngOnInit(): void {
        const elements = this.document.querySelectorAll('#apx.customer-portal-wrappper-cp');
        if (elements.length > 0) {
            this.renderer2.addClass(elements[0], 'dialog-backdrop');
        }
    }
    ngOnDestroy(): void {
        const elements = this.document.querySelectorAll('#apx.customer-portal-wrappper-cp');
        if (elements.length > 0) {
            this.renderer2.removeClass(elements[0], 'dialog-backdrop');
        }
    }
    sendPrompt(answer: boolean) {
        this.confirmed.emit(answer);
    }
}
