import { Consumed } from '../enums/consumed-payment-verify';
import { PaymentMethodEnums } from '../enums/payment-method.enum';
import { Address } from '../types';
import { ContractPdfResponse } from './pdf-response';
import { Searchable } from './searchable.model';

/* eslint-disable @typescript-eslint/naming-convention */
// Note CamelCase: Some fields are GAPI Model
export interface ContractCP extends Searchable {
    closeDate: string;
    contractId: string;
    contractName: string;
    customerId: string;
    info: string;
    itemsOut: boolean;
    jobNumber: string;
    openDate: string;
    paid: string;
    purchaseOrder: string;
    status: string;
    GrandTotal: string;
    AmountDue: string;
    LineItems?: LineItems[];
    depotId?: string;
    CurrencyCode?: string;
    Addresses?: {
        line1?: string;
        line2?: string;
        line3?: string;
        city?: string;
        Country?: string;
        postalCode?: string;
        Latitude?: string;
        Longitude?: string;
    };
    name?: string;
    latLongResult?: {
        lat?: number;
        long?: number;
    };
    payment?: Payment[];
    paymentPending?: boolean;
    latestToShow?: boolean;
}

// Note CamelCase: GAPI model
export interface ContractDetail {
    Id: string;
    CustomerId?: string;
    Name: string;
    ContractStatus: string;
    Status?: string;
    GrandTaxTotal?: string;
    GrandTotal: string;
    AmountDue: number;
    EndDateTime: string;
    StartDateTime: string;
    LineItems: LineItems[];
    Payment?: Payment[];
    Pdf?: ContractPdfResponse;
    DepotId: string;
    CustomerName?: string;
    DriverDelivery: DriverDelivery;
    DriverPickup: DriverPickup;
    ESignState: string;
    EsignUrl: string;
    CurrencyCode?: string;
}

// Note CamelCase: GAPI model
export interface LineItems {
    ModelType: string;
    Id: string;
    Name: string;
    Identifiers: {
        Key: string;
        Model: string;
        ParentId: string;
        PartNumber?: string;
    };
    Classification: string;
    EndDateTime: string;
    HasFuel: boolean;
    IsSerialized: boolean;
    ItemId: string;
    LineTaxTotal: number;
    LineTotal: number;
    LocationId: string;
    PONumber?: string;
    ProductId: string;
    Quantity: number;
    Rates: number[];
    SerialNumber?: string;
    StartDateTime: string;
    Status: string;
    StockId: string;
    SortOrder?: number;
    AdditionalFields: {
        ContractId: string;
        CustomerJobNumber?: string;
    };
    CreatedDateTime: string;
    UpdatedDateTime: string;
    Hidden: number;
    SingleRate: string | number;
    isNoter?: boolean;
}

// Note CamelCase: GAPI model
export interface Payment {
    Id: number;
    Consumed: Consumed;
    ContractId: string;
    Currency: string;
    DepotId: string;
    PaidBy: {
        Address: {
            Line1: string;
        };
        CompanyName: string;
        Email: string;
        FirstName: string;
        LastName: string;
        Phone: {
            Number: string;
        };
    };
    Processor: string;
    ReferenceNumber: string;
    PaidDateTime: string;
    Source: string;
    TotalPaid: number;
    PaymentMethod: PaymentMethodEnums;
}

export interface DriverPickup {
    LocationName: string;
    Address: {
        Line1: string;
    };
    Instructions: string;
    DateTime: string;
}

export interface DriverDelivery {
    LocationName: string;
    Address: {
        Line1: string;
    };
    Instructions: string;
    DateTime: string;
    DeliveryAddress?: Address | null;
}

export interface GetDocumentParam {
    contractId: string;
    customerId: string;
    orgType: string;
    documentValue?: string;
}
