import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { clearContractDetailInStore } from '../actions/contract.actions';
import { setActiveTab } from '../actions/active-tab.actions';

@Injectable()
export class ActiveTabEffects {
    constructor(private readonly actions$: Actions) {}

    activeTabChange$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setActiveTab),
            map(() => clearContractDetailInStore())
        )
    );
}
