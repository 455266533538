<ng-container [ngSwitch]="activeTemplate$ | async">
    <ng-container *ngSwitchCase="'DefaultView'">
        <ng-container *ngIf="contractDetail" [ngTemplateOutlet]="contractDetailTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'ProductDetail'">
        <ng-container [ngTemplateOutlet]="singleProductPageTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'SelectedItemActions'">
        <ng-container [ngTemplateOutlet]="selectedItemActionsTemplate"></ng-container>
    </ng-container>
</ng-container>

<ng-template #contractDetailTemplate>
    <div
        class="contract-details-page"
        [ngClass]="{ 'quick-link': versionToggle.isQLButNotConsumerQL(), 'display-block': (appFacadeService.isPaymentSectionEnabled() | async) }"
        [class.sticky-added]="isSticky">
        <div class="header-section" [ngClass]="{ hide: (appFacadeService.isPaymentSectionEnabled() | async) === false && versionToggle.isQLButNotConsumerQL() }">
            <ng-container *ngTemplateOutlet="versionToggle.isQLButNotConsumerQL() ? quickLinkTemplate : normalHeadingTemplate"></ng-container>

            <ng-template #quickLinkTemplate>
                <div class="header" *ngIf="(appFacadeService.isPaymentSectionEnabled() | async) && versionToggle.isQLButNotConsumerQL()">
                    <p>{{ 'Make a payment' | translate }}</p>
                    <por-base-button [featureName]="'payment-detail-close'" (click)="enablePaymentPage(false)" icon="close"></por-base-button>
                </div>
            </ng-template>
            <ng-template #normalHeadingTemplate>
                <div class="header">
                    <h1 class="theme-heading">{{ 'Contract Details' | translate }}</h1>
                    <por-base-button [featureName]="'contract-detail-close'" (click)="onCloseButtonClick()" icon="close"></por-base-button>
                </div>
            </ng-template>
        </div>

        <div class="content" *ngIf="contractDetail && (appFacadeService.isPaymentSectionEnabled() | async) === false">
            <ng-container *ngFor="let detail of contractDetail">
                <div *ngIf="versionToggle.isNotQLOrConsumerQL()">
                    <ng-container [ngTemplateOutlet]="dates"></ng-container>

                    <div class="detail">
                        <ng-container [ngTemplateOutlet]="stats"></ng-container>
                        <ng-container [ngTemplateOutlet]="payBtns" *ngIf="!isMobile"></ng-container>
                    </div>
                    <ng-container [ngTemplateOutlet]="lineItemTable"></ng-container>
                </div>
                <div *ngIf="versionToggle.isQLButNotConsumerQL()" class="quick-link-version">
                    <div *ngIf="isMobile">
                        <div class="action-buttons" *ngIf="hideActionButtons()" [class.sticky]="isSticky">
                            <ng-container [ngTemplateOutlet]="selectedItemDocumentsTemplate"></ng-container>
                        </div>
                        <! -- accordian start -->
                        <por-accordion>
                            <por-accordion-group title="{{ 'information' | translate }}">
                                <div class="stats">
                                    <p>
                                        <label porAddFeature featureName="contract-detail-quote-label">{{ 'contract' | translate }}#</label>
                                        <span>{{ detail?.Name }}</span>
                                    </p>
                                    <p>
                                        <label porAddFeature featureName="contract-detail-start-date-label">{{ 'Start Date' | translate }}</label>
                                        <span>{{ detail.StartDateTime | date : dateFormat }}</span>
                                    </p>

                                    <p *ngIf="detail.EndDateTime">
                                        <label porAddFeature featureName="contract-detail-end-date-label">{{ 'End Date' | translate }}</label>
                                        <span>{{ detail.EndDateTime | date : dateFormat }}</span>
                                    </p>
                                    <p>
                                        <label porAddFeature featureName="contract-detail-grand-total-label" class="label-relative">
                                            {{ 'Grand Total' | translate }}
                                        </label>
                                        <span>{{ detail.GrandTotal | formatCurrency }}</span>
                                    </p>
                                    <p *ngIf="contractService.getContractTotalPaid(detail) > 0">
                                        <label porAddFeature featureName="contract-detail-total-paid-label" class="label-relative">
                                            {{ 'TotalPaid' | translate }}
                                            <span
                                                apxIcon
                                                icon="info"
                                                class="payment-info quicklink mt-05"
                                                *ngIf="hasPaymentHistory()"
                                                (click)="openPaymentHistoryModal()"
                                                title="{{ 'OnlinePaymentHistory' | translate }}"></span>
                                        </label>
                                        <span>{{ contractService.getContractTotalPaid(detail) | formatCurrency }}</span>
                                    </p>
                                </div>
                            </por-accordion-group>
                            <ng-template porFeatureAvailability [featureToken]="['canSeeBalance']">
                                <p class="last-states" *ngIf="versionToggle.isQLButNotConsumerQL()">
                                    <label porAddFeature featureName="contract-detail-amount-due-label">{{ 'AmountDue' | translate }}:</label>
                                    <ng-container>
                                        <span *ngIf="isPending; else showDash">{{ 'Pending' | translate }}</span>
                                        <ng-template #showDash>
                                            <span>{{ detail.AmountDue | formatCurrency }}</span>
                                        </ng-template>
                                    </ng-container>
                                </p>
                            </ng-template>
                            <por-accordion-group title="{{ 'items' | translate }}">
                                <ng-container [ngTemplateOutlet]="lineItemTable"></ng-container>
                            </por-accordion-group>
                        </por-accordion>
                        <! -- accordian ends -->
                    </div>
                    <div *ngIf="!isMobile">
                        <ng-container [ngTemplateOutlet]="dates"></ng-container>
                        <div class="detail">
                            <ng-container [ngTemplateOutlet]="stats"></ng-container>
                            <ng-container [ngTemplateOutlet]="payBtns"></ng-container>
                        </div>
                        <ng-container [ngTemplateOutlet]="lineItemTable"></ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="content">
            <ng-container *ngIf="contractDetail && (appFacadeService.isPaymentSectionEnabled() | async)">
                <por-payment-page
                    [contract]="contractDetail[0]"
                    [customerId]="customerId"
                    [organizationId]="organizationId"
                    [enableBackButton]="true"
                    (paymentSuccess)="onSuccessTransaction($event)"
                    (closePanel)="enablePaymentPage(false)"></por-payment-page>
            </ng-container>
        </div>
        <div class="quick-link-pay-btns-mobiles" *ngIf="isMobile && (appFacadeService.isPaymentSectionEnabled() | async) === false">
            <ng-container [ngTemplateOutlet]="payBtns"></ng-container>
        </div>
        <div *ngIf="versionToggle.isQuickLinkVersion() && versionToggle.isAdvanceVersion() && (!isMobile || (appFacadeService.isPaymentSectionEnabled() | async) === false)">
            <por-ad-images [customerId]="customerId" [layout]="'columns4'"></por-ad-images>
        </div>
    </div>
</ng-template>

<ng-template #singleProductPageTemplate>
    <ng-container>
        <div class="product-detail" *ngIf="(appFacadeService.getProductDetail() | async)?.productDetail">
            <por-product-detail (closePanel)="onCloseProductDetail()" [uiUrl]="uiUrl" />
        </div>
    </ng-container>
</ng-template>

<ng-template #stats>
    <ng-container *ngFor="let detail of contractDetail">
        <div class="stats">
            <p>
                <label *ngIf="isStatus('Quote'); else contractLabel" porAddFeature featureName="contract-detail-quote-label">{{ 'Quote' | translate }}#</label>
                <ng-template #contractLabel>
                    <label porAddFeature featureName="contract-detail-quote-label">{{ 'contract' | translate }}#</label>
                </ng-template>
                <span>{{ detail?.Name }}</span>
            </p>
            <ng-template porFeatureAvailability [featureToken]="['canSeeBalance']">
                <p>
                    <label porAddFeature featureName="contract-detail-balance-label">{{ 'balance' | translate }}</label>
                    <ng-container>
                        <span *ngIf="isPending; else showDash">{{ 'Pending' | translate }}</span>
                        <ng-template #showDash>
                            <span>{{ detail.AmountDue | formatCurrency }}</span>
                        </ng-template>
                    </ng-container>
                </p>
            </ng-template>
            <p>
                <label porAddFeature featureName="contract-detail-contract-total-label">{{ 'contractTotal' | translate }}</label>
                <span>{{ getContractTotal() | formatCurrency }}</span>
            </p>
            <p>
                <label porAddFeature featureName="contract-detail-total-tax-label">{{ 'totalTax' | translate }}</label>
                <span>{{ detail?.GrandTaxTotal | formatCurrency }}</span>
            </p>
            <p class="last-states">
                <label porAddFeature featureName="contract-detail-grand-total-label" class="label-relative">
                    {{ 'Grand Total' | translate }}
                </label>
                <span>{{ detail.GrandTotal | formatCurrency }}</span>
            </p>
            <p *ngIf="contractService.getContractTotalPaid(detail) > 0">
                <label porAddFeature featureName="contract-detail-total-paid-label" class="label-relative">
                    {{ 'TotalPaid' | translate }}
                    <span apxIcon icon="info" class="payment-info" *ngIf="hasPaymentHistory()" (click)="openPaymentHistoryModal()" title="{{ 'OnlinePaymentHistory' | translate }}"></span>
                </label>
                <span>{{ contractService.getContractTotalPaid(detail) | formatCurrency }}</span>
            </p>
        </div>
    </ng-container>
</ng-template>

<ng-template #dates>
    <ng-container *ngFor="let detail of contractDetail">
        <div class="action-buttons" *ngIf="hideActionButtons()">
            <ng-container [ngTemplateOutlet]="selectedItemDocumentsTemplate"></ng-container>
        </div>
        <hr class="spliter" [ngClass]="{ 'show-line': ((isPaymentProcessorAvailable$ | async) && isPaymentEnabled) || checkEsignButton() }" />
        <div class="dates">
            <div>
                <p *ngIf="versionToggle.isNotQLOrConsumerQL()">
                    <label porAddFeature featureName="contract-detail-customer-name-label">{{ 'Customer' | translate }}:</label>
                    <span>{{ detail?.CustomerName }}</span>
                </p>
            </div>
            <div>
                <p>
                    <label porAddFeature featureName="contract-detail-start-date-label">{{ 'Contract Start Date' | translate }} :</label>
                    <span>{{ detail.StartDateTime | date : dateFormat }}</span>
                </p>

                <p *ngIf="versionToggle.isNotQLOrConsumerQL() && detail.DriverDelivery && detail.DriverDelivery.DateTime">
                    <label porAddFeature featureName="contract-detail-delivery-date-label">{{ 'Delivery Date' | translate }} :</label>
                    <span>{{ detail.DriverDelivery.DateTime | date : dateFormat }}</span>
                </p>
            </div>
            <div>
                <p *ngIf="detail.EndDateTime">
                    <label porAddFeature featureName="contract-detail-end-date-label">{{ 'End Date' | translate }} :</label>
                    <span>{{ detail.EndDateTime | date : dateFormat }}</span>
                </p>
                <p *ngIf="versionToggle.isNotQLOrConsumerQL() && this.hasPickupDate()">
                    <label porAddFeature featureName="contract-detail-pickup-date-label">{{ 'Pickup Date' | translate }} :</label>
                    <span>{{ detail.DriverPickup.DateTime | date : dateFormat }}</span>
                </p>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #payBtns>
    <ng-container *ngFor="let detail of contractDetail">
        <div class="pay-btns">
            <ng-template
                porFeatureAvailability
                [featureToken]="['contractCancel']"
                [options]="{ subFeature: 'canContractCancel', state: detail?.Status ?? detail?.ContractStatus ?? '' }"
                porFeatureAvailability
                [featureToken]="['contractEdit']"
                [options]="{ subFeature: 'canContractEdit', state: detail?.Status ?? detail?.ContractStatus ?? '' }">
                <span class="actions-btns">
                    <ng-template porFeatureAvailability [featureToken]="['contractEdit']" [options]="{ subFeature: 'canContractEdit', state: detail?.Status ?? detail?.ContractStatus ?? '' }">
                        <por-base-button [theme]="'primary'" [class]="'edit-button'" [featureName]="'edit-button'" (click)="editContract(detail.Id)">
                            <img class="actions-image" src="{{ uiUrl }}/assets/images/edit.svg" />
                        </por-base-button>
                    </ng-template>
                    <ng-template porFeatureAvailability [featureToken]="['contractCancel']" [options]="{ subFeature: 'canContractCancel', state: detail?.Status ?? detail?.ContractStatus ?? '' }">
                        <por-base-button [theme]="'primary'" [class]="'cancel-button'" [featureName]="'cancel-button'" (click)="toggleDialog()">
                            <img class="actions-image" src="{{ uiUrl }}/assets/images/cancel.svg" />
                        </por-base-button>
                    </ng-template>
                </span>
            </ng-template>
            <span class="pdf-btn" *ngIf="hideCloneContractButton()">
                <por-base-button
                    *ngIf="versionToggle.isAdvanceVersion() && versionToggle.isNotQLOrConsumerQL()"
                    [theme]="'primary'"
                    [class]="'clone-button'"
                    [featureName]="'clone-contract-button'"
                    title="{{ 'RequestToBookAgainContract' | translate }}"
                    (click)="openContractCloneModal()">
                    <img class="actions-image" src="{{ uiUrl }}/assets/images/copy.svg" />
                </por-base-button>
            </span>
            <ng-container *ngIf="pdfDownloadAvailable$ | async">
                <span *ngIf="pdfDownloadUrl$ | async as data" class="pdf-btn">
                    <por-base-button
                        *ngIf="data?.SecureURL || data?.URL"
                        (click)="downloadPdf(data?.SecureURL || data?.URL)"
                        [featureName]="'download-pdf'"
                        [title]="!(pdfDownloadAvailable$ | async | convertBoolean) ? ('Coming Soon' | translate) : ''"
                        [disabled]="(pdfDownloadAvailable$ | async | convertBoolean) === true ? false : true">
                        <img class="actions-image" [ngClass]="{ disable: !(pdfDownloadAvailable$ | async | convertBoolean) }" src="{{ uiUrl }}/assets/images/noun-pdf-file-5441350.svg" />
                    </por-base-button>
                </span>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #lineItemTable>
    <ng-container *ngFor="let detail of contractDetail">
        <div *ngIf="isMobile" [ngClass]="{ 'line-items-table': true, 'quick-link-table': versionToggle.isQuickLinkVersion() }">
            <table>
                <thead>
                    <th>{{ 'Item Name' | translate }}</th>
                    <th class="text-center">{{ 'Quantity' | translate }}</th>
                    <th class="text-center">{{ 'Type' | translate }}</th>
                    <th class="text-center">{{ 'Status' | translate }}</th>
                    <th class="text-center">{{ 'Rate' | translate }}</th>
                    <th class="text-center">{{ 'Total' | translate }}</th>
                    <th>{{ 'Item' | translate }}#</th>
                    <ng-template porFeatureAvailability [featureToken]="['showDetailDocuments']">
                        <th class="text-center">{{ 'Documents' | translate }}</th>
                    </ng-template>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of detail.LineItems; let i = index">
                        <tr *ngIf="i < displayedRowCount">
                            <ng-template #showLink>
                                <td class="text-left">
                                    <span *ngIf="!isNoterLineItem(item)">
                                        <a
                                            href="javascript:void(0)"
                                            (click)="loadProductDetailModal(item?.ProductId, item?.StockId)"
                                            porAddFeature
                                            featureName="contract-detail-lineitem"
                                            [dynamicValue]="itemOutService.getLineItemKeyName(item)"
                                            *ngIf="isProductDetailAvailable; else productDetailNotAvailable">
                                            {{ itemOutService.getLineItemKeyName(item) }}
                                        </a>
                                    </span>
                                    <ng-template #productDetailNotAvailable>
                                        {{ itemOutService.getLineItemKeyName(item) }}
                                    </ng-template>
                                </td>
                            </ng-template>
                            <ng-template #itemName>
                                <td [ngClass]="isNoterLineItem(item) ? 'noter-line-item' : 'text-left'">
                                    <span>{{ item?.Name }}</span>
                                </td>
                            </ng-template>
                            <ng-template porFeatureAvailability [featureToken]="['canHaveSelectedItemsActions']" [fallbackTemplate]="itemName">
                                <td [ngClass]="isNoterLineItem(item) ? 'noter-line-item' : 'text-left'">
                                    <ul>
                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                (click)="loadItemActions(item)"
                                                class="btn-link"
                                                porAddFeature
                                                featureName="contract-detail-lineitem"
                                                [dynamicValue]="item?.Name">
                                                <span>{{ item?.Name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </ng-template>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ item?.Quantity }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ isSaleorRentalorNull(item.Classification) ? item?.Classification : '' }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ item?.Status }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ isRental(item.Classification) ? (item?.SingleRate | formatCurrency) : '' }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ item?.LineTotal !== null ? (item?.LineTotal | formatCurrency) : (0 | formatCurrency) }}</span>
                            </td>
                            <td *ngIf="!(item?.ProductId ?? item?.StockId); else showLink">
                                <span *ngIf="!isNoterLineItem(item)">{{ itemOutService.getLineItemKeyName(item) }}</span>
                            </td>
                            <ng-template porFeatureAvailability [featureToken]="['showDetailDocuments']">
                                <td *ngIf="isRental(item.Classification)" class="document-data">
                                    <div class="doc-btn" *ngIf="!isNoterLineItem(item)">
                                        <por-base-button (click)="loadDocuments(item)">
                                            <img class="actions-image" src="{{ uiUrl }}/assets/images/document-icon.svg" />
                                        </por-base-button>
                                    </div>
                                </td>
                            </ng-template>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <div class="line-items-table" *ngIf="!isMobile">
            <table>
                <thead>
                    <th class="text-left">{{ 'Item' | translate }}#</th>
                    <th class="text-left">{{ 'Item Name' | translate }}</th>
                    <th class="text-center">{{ 'Quantity' | translate }}</th>
                    <th class="text-center">{{ 'Type' | translate }}</th>
                    <th class="text-center">{{ 'Status' | translate }}</th>
                    <th class="text-center">{{ 'Rate' | translate }}</th>
                    <th class="text-center">{{ 'Total' | translate }}</th>
                    <ng-template porFeatureAvailability [featureToken]="['showDetailDocuments']">
                        <th class="text-center">{{ 'Documents' | translate }}</th>
                    </ng-template>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of detail.LineItems; let i = index">
                        <tr *ngIf="i < displayedRowCount">
                            <td *ngIf="!(item?.ProductId ?? item?.StockId); else showLink" class="text-left">
                                <span *ngIf="!isNoterLineItem(item)">{{ itemOutService.getLineItemKeyName(item) }}</span>
                            </td>
                            <ng-template #showLink>
                                <td class="text-left">
                                    <span *ngIf="!isNoterLineItem(item)">
                                        <a
                                            href="javascript:void(0)"
                                            porAddFeature
                                            featureName="contract-detail-lineitem"
                                            [dynamicValue]="itemOutService.getLineItemKeyName(item)"
                                            (click)="loadProductDetailModal(item?.ProductId, item?.StockId)"
                                            *ngIf="isProductDetailAvailable; else productDetailNotAvailable">
                                            {{ itemOutService.getLineItemKeyName(item) }}
                                        </a>
                                    </span>
                                    <ng-template #productDetailNotAvailable>
                                        {{ itemOutService.getLineItemKeyName(item) }}
                                    </ng-template>
                                </td>
                            </ng-template>
                            <ng-template #itemName>
                                <td [ngClass]="isNoterLineItem(item) ? 'noter-line-item' : 'text-left'">
                                    <span>{{ item?.Name }}</span>
                                </td>
                            </ng-template>
                            <ng-template porFeatureAvailability [featureToken]="['canHaveSelectedItemsActions']" [fallbackTemplate]="itemName">
                                <td [ngClass]="isNoterLineItem(item) ? 'noter-line-item' : 'text-left'">
                                    <ul>
                                        <li>
                                            <a
                                                href="javascript:void(0)"
                                                (click)="loadItemActions(item)"
                                                class="btn-link"
                                                porAddFeature
                                                featureName="contract-detail-lineitem"
                                                [dynamicValue]="item?.Name">
                                                <span>{{ item?.Name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </ng-template>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ item?.Quantity }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ isSaleorRentalorNull(item.Classification) ? item?.Classification : '' }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ item?.Status }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ isRental(item.Classification) ? (item?.SingleRate | formatCurrency) : '' }}</span>
                            </td>
                            <td class="text-center">
                                <span *ngIf="!isNoterLineItem(item)">{{ item?.LineTotal !== null ? (item?.LineTotal | formatCurrency) : (0 | formatCurrency) }}</span>
                            </td>
                            <ng-template porFeatureAvailability [featureToken]="['showDetailDocuments']">
                                <td *ngIf="isRental(item.Classification)" class="document-data">
                                    <div class="doc-btn" *ngIf="!isNoterLineItem(item)">
                                        <por-base-button (click)="loadDocuments(item)">
                                            <img class="actions-image" src="{{ uiUrl }}/assets/images/document-icon.svg" />
                                        </por-base-button>
                                    </div>
                                </td>
                            </ng-template>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
        <div *ngIf="detail.LineItems.length > displayedRowCount" class="middle">
            <por-load-more
                [featureName]="'contract-detail-load-more'"
                [displayedRowCount]="displayedRowCount"
                [totalRowCount]="detail.LineItems.length"
                (loadMore)="handleLoadMore($event)"></por-load-more>
        </div>
    </ng-container>
</ng-template>

<ng-container *ngFor="let detail of contractDetail">
    <por-app-confirm-dialog [dialogType]="dialogType" (confirmed)="cancelContract($event, detail.Id)" *ngIf="isDialogEnabled"></por-app-confirm-dialog>
</ng-container>

<por-base-modal [id]="'clone-contract-request-modal'" [modalWidth]="'modal-lg'" (onclose)="onCloseContractCloneModal()">
    <por-book-again-contract-modal
        [customerId]="customerId"
        [contractDetail]="contractDetail"
        [uiUrl]="uiUrl"
        (isContractFormSubmittedSuccess)="closeContractCloneModal()"></por-book-again-contract-modal>
</por-base-modal>

<por-base-modal [id]="'contract-payment-history-modal'" [modalWidth]="'modal-lg'" (onclose)="onClosePaymentHistoryModal()">
    <por-contract-payment-history-modal [contractDetail]="contractDetail"></por-contract-payment-history-modal>
</por-base-modal>

<ng-template #selectedItemActionsTemplate>
    <por-selected-item-actions
        (closePanel)="setActiveTemplateTab('DefaultView')"
        [lineItem]="selectedLineItem"
        [uiUrl]="uiUrl"
        [customerId]="customerId"
        [contractDetail]="contractDetail?.[0]"></por-selected-item-actions>
</ng-template>

<ng-template #selectedItemDocumentsTemplate>
    <div class="e-signbtn-wrapper" *ngIf="versionToggle.isQuickLinkVersion() && this.isEsignAvailable()">
        <por-base-button [theme]="'primary'" class="split-button" (click)="openEsign()">
            <span class="actions-image">
                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="40" viewBox="0 0 40 40">
                    <defs>
                        <clipPath id="clip-esignature">
                            <rect width="40" height="40" />
                        </clipPath>
                    </defs>
                    <g id="esignature" clip-path="url(#clip-esignature)">
                        <g id="contract_5_" data-name="contract (5)" transform="translate(3 3)">
                            <path
                                id="Path_11288"
                                data-name="Path 11288"
                                d="M24.9,25.078a8.313,8.313,0,0,1-5.392,2.4,6.055,6.055,0,0,1-3.633,1.075,1.96,1.96,0,0,1-1.756-.774,2.27,2.27,0,0,1-.3-1.35,2.6,2.6,0,0,0-1.495.771.992.992,0,0,1-1.4-1.4,4.6,4.6,0,0,1,2.827-1.354,1.96,1.96,0,0,1,1.756.774,2.269,2.269,0,0,1,.3,1.351,4.608,4.608,0,0,0,2.279-.522,8.192,8.192,0,0,1,2.4-5.38l4.883-4.884V9.354a.992.992,0,0,0-.992-.992H18a.992.992,0,0,1-.992-.992V.992A.992.992,0,0,0,16.014,0H.992A.992.992,0,0,0,0,.992V32.879a.992.992,0,0,0,.992.992H24.376a.992.992,0,0,0,.992-.992V24.606ZM5.244,10.629H9.5a.992.992,0,0,1,0,1.985H5.244A.992.992,0,0,1,5.244,10.629Zm0,4.252H20.124a.992.992,0,0,1,0,1.985H5.244a.992.992,0,0,1,0-1.985Zm-.992,5.244a.992.992,0,0,1,.992-.992H15.873a.992.992,0,0,1,0,1.985H5.244A.992.992,0,0,1,4.252,20.124Z" />
                            <path
                                id="Path_11289"
                                data-name="Path 11289"
                                d="M286.6,14.113h5.385a2.992,2.992,0,0,1,.444.033l-5.863-5.863a2.992,2.992,0,0,1,.033.444v5.385Z"
                                transform="translate(-267.604 -7.736)" />
                            <path
                                id="Path_11290"
                                data-name="Path 11290"
                                d="M317.848,177.159a1.462,1.462,0,0,0-1.027-.426.361.361,0,0,0-.255.106l-10.274,10.274a6.222,6.222,0,0,0-1.741,3.341,6.311,6.311,0,0,0,3.346-1.739l10.272-10.275a.361.361,0,0,0,.106-.255A1.463,1.463,0,0,0,317.848,177.159Z"
                                transform="translate(-284.402 -165.041)" />
                        </g>
                    </g>
                </svg>
            </span>
            <span class="line-splitter">{{ 'E-Sign' | translate }}</span>
        </por-base-button>
        <p>
            <span>{{ esignMsg }}</span>
        </p>
    </div>
    <ng-container *ngIf="isPaymentProcessorAvailable$ | async">
        <por-base-button [theme]="'primary'" [featureName]="'payment-button'" class="split-button" (click)="enablePaymentPage(true)" *ngIf="isPaymentEnabled">
            <span class="actions-image">
                <svg
                    class="fill-current"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 100"
                    style="enable-background: new 0 0 100 100"
                    xml:space="preserve">
                    <g id="credit-card-payment" transform="translate(3 2)">
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path id="Path_11200-2" d="M77.1,36.8c1.2,0,2.3,0.2,3.4,0.5l9.8-9.8l-4.6-4.6L71.9,36.8H77.1z" />
                        </g>
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path
                                id="Path_11201-2"
                                d="M96.9,34.1l-2.5-2.5l-8.9,8.9c2,2.1,3.1,4.9,3.1,7.8v2.3l8.3-8.3C99.1,40,99.1,36.3,96.9,34.1
                            C96.9,34.1,96.9,34.1,96.9,34.1z" />
                        </g>
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path id="Path_11202-2" d="M81.7,18.8L65.2,2.4c-2.2-2.2-5.9-2.2-8.1,0c0,0,0,0,0,0L22.7,36.8h41L81.7,18.8z" />
                        </g>
                        <path id="Path_11203" d="M12.6,58.7h8.8v6.5h-8.8V58.7z" />
                        <g transform="matrix(1, 0, 0, 1, -3, -2)">
                            <path
                                id="Path_11204-2"
                                d="M77.1,42.5H6.2c-3.2,0-5.7,2.6-5.7,5.7V93c0,3.2,2.6,5.7,5.7,5.7c0,0,0,0,0,0h70.8
                            c3.2,0,5.7-2.6,5.7-5.7l0,0V48.2C82.8,45.1,80.2,42.5,77.1,42.5z M9.8,55h20.2v18H9.8V55z M21,86.3H9.7v-5.7H21V86.3z M38.6,86.3
                            H27.2v-5.7h11.4V86.3z M56.1,86.3H44.8v-5.7h11.3V86.3z M73.6,86.3H62.3v-5.7h11.4L73.6,86.3z" />
                        </g>
                    </g>
                </svg>
            </span>
            <span class="line-splitter">{{ 'Make a payment' | translate }}</span>
        </por-base-button>
    </ng-container>
</ng-template>

<por-payment-success-dialog *ngIf="versionToggle.isQuickLinkVersion()"></por-payment-success-dialog>
