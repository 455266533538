import { setAuthenticated, setRedirected } from '../actions/auth.actions';
import { createReducer, on } from '@ngrx/store';

export interface Authenticated {
    isAuthenticated: boolean;
}

export const initalAuthState: Authenticated = {
    isAuthenticated: false
};

export const authReducer = createReducer(
    initalAuthState,
    on(setAuthenticated, (state, { isAuthenticated }) => {
        return { ...state, isAuthenticated };
    }),
    on(setRedirected, (state, { isRedirected }) => {
        return { ...state, isRedirected };
    })
);
