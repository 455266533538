import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, map, of } from 'rxjs';
import { catchError, concatMap, finalize, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ModalService } from '../../services';
import {
    clearContractinStore,
    ContractActionTypes,
    failLoadContractDetails,
    fetchContractDetail,
    fetchContracts,
    loadContractAction,
    setContractDetailData,
    setNoMoreRecord,
    setSelectedContracts
} from '../actions/contract.actions';
import { select, Store } from '@ngrx/store';
import isEmpty from 'lodash-es/isEmpty';
import { ConsumerPortalApiService } from '../../services/consumer-portal-api.service';
import { AppFacadeService } from '../../services/app-facade.service';
import { TranslateService } from '@ngx-translate/core';
import { getPageNumber } from '../selector/contracts.selectors';
import { ContractCP, ContractDetail } from '../../models/contract-model';
import { ApiHeaders } from '../../models/api-query.model';
import { setContractCurrency } from '../actions/currencycode.actions';
import { ContractFilterStatus } from '../../enums/contract-filter-status.enum';
import { ContractService } from '../../services/contract.service';
import { selectRouteParamsContractCustomerId, selectRouteParamsContractId } from '../selector/config.selectors';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ContractEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store,
        private readonly consumerPortalApi: ConsumerPortalApiService,
        private readonly appFacadeService: AppFacadeService,
        private readonly translateService: TranslateService,
        private readonly modalService: ModalService,
        private readonly contractService: ContractService
    ) {}

    onPaymentOpen$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ContractActionTypes.MultiPaymentContinue),
                map(() => this.modalService.open('payment-modal'))
            ),
        { dispatch: false }
    );

    onPaymentClose$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ContractActionTypes.PaymentWindowClosed),
                map(() => this.modalService.close('payment-modal'))
            ),
        { dispatch: false }
    );

    fetchContracts$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchContracts),
            tap(() => this.appFacadeService.setLoading(true)),
            withLatestFrom(this.store.pipe(select(getPageNumber)), this.appFacadeService.getPageSizeValues()),
            switchMap(([action, currentPageNumber, pageSizeValues]) => {
                const { selectedPageSize, previousPageSize } = pageSizeValues;

                const { customerId, status = [], startDate, endDate, search } = action;
                let pageNumber = currentPageNumber;
                if (previousPageSize !== selectedPageSize) {
                    this.store.dispatch(clearContractinStore());
                    pageNumber = 1;
                }
                const headers: ApiHeaders = this.prepareHeaders(pageNumber, customerId, status, startDate, endDate, search, Number(selectedPageSize));

                return this.consumerPortalApi.get<ContractCP>(`customer/${customerId}/contracts`, { headers }).pipe(
                    switchMap((contracts: ContractCP[]) => {
                        if (!contracts || contracts.length === 0) {
                            return of(setNoMoreRecord({ record: true }));
                        }

                        contracts = contracts.map((contract: ContractCP) => ({
                            ...contract,
                            status: this.translateService.instant(contract.status)
                        }));

                        return of(loadContractAction({ contracts, pageSize: Number(selectedPageSize) }), setContractCurrency({ currencyCode: contracts[0]?.CurrencyCode || '' }));
                    }),
                    catchError(() => of(setNoMoreRecord({ record: true }))),
                    finalize(() => {
                        this.appFacadeService.setLoading(false);
                    })
                );
            })
        )
    );
    fetchContractDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fetchContractDetail),
            tap(() => this.appFacadeService.setLoading(true)),
            switchMap(({ forPayment, customerId, contractId }) =>
                combineLatest([this.store.pipe(select(selectRouteParamsContractCustomerId)), this.store.pipe(select(selectRouteParamsContractId))]).pipe(
                    take(1),
                    map(([customerIdFromState, contractIdFromState]) => ({
                        customerId: customerId ?? customerIdFromState,
                        contractId: contractId ?? contractIdFromState,
                        forPayment
                    }))
                )
            ),
            switchMap(({ forPayment, customerId, contractId }) => {
                if (!customerId || !contractId) {
                    return of(failLoadContractDetails({ error: 'Missing customerId or contractId' }));
                }
                return this.contractService.getContractsDetails(customerId, contractId).pipe(
                    concatMap((detail: ContractDetail[]) => {
                        const data = detail[0];
                        const contract: ContractCP = this.contractService.convertContractDetailToContractCP(data);
                        const actions = [];
                        if (forPayment) {
                            actions.push(loadContractAction({ contracts: [contract] }));
                            actions.push(setSelectedContracts({ selectedContracts: [contractId] }));
                        }
                        actions.push(setContractDetailData({ data }));
                        // Convert the array of actions into an observable sequence
                        return of(...actions);
                    }),
                    catchError((error: HttpErrorResponse) => of(failLoadContractDetails({ error: error?.message }))),
                    finalize(() => {
                        this.appFacadeService.setLoading(false);
                    })
                );
            })
        )
    );

    private prepareHeaders(pageNumber: number, customerId: string, status?: string[], startDate?: string, endDate?: string, search?: string, pageSize?: number): ApiHeaders {
        const columns = [
            'Id',
            'CustomerId',
            'Name',
            'Status',
            'ContractStatus',
            'StartDateTime',
            'EndDateTime',
            'PONumber',
            'AdditionalFields',
            'Addresses',
            'GrandTaxTotal',
            'GrandTotal',
            'AmountDue',
            'DepotId',
            'LineItems'
        ];
        let headers: ApiHeaders = {
            /* eslint-disable @typescript-eslint/naming-convention */
            'x-columns': JSON.stringify(columns)
        };

        if (pageNumber && customerId) {
            headers = {
                ...headers,
                /* eslint-disable @typescript-eslint/naming-convention */
                'x-paging': JSON.stringify({ page: pageNumber, pageSize: pageSize })
            };
        }

        const finalFilter = this.buildFilters(status, search, startDate, endDate);

        if (finalFilter.length > 0) {
            headers = {
                ...headers,
                /* eslint-disable @typescript-eslint/naming-convention */
                'x-filter': JSON.stringify(finalFilter)
            };
        }

        return headers;
    }

    private buildFilters(status?: string[], search?: string, startDate?: string, endDate?: string): any[] {
        const filters = [];

        if (this.appFacadeService.versionToggleService.isBasicVersion()) {
            filters.push({ field: 'Status', type: '!=', value: ContractFilterStatus.Closed });
            filters.push({ field: 'AmountDue', type: '>', value: 0 });
        }

        if (search && !isEmpty(search)) {
            const searchFilters = this.appFacadeService.featureToggleService.isAvailable('textSearchMultipleFilters')
                ? [
                      { field: 'AdditionalFields.CustomerPurchaseOrder', type: 'LIKE', value: search },
                      { field: 'AdditionalFields.CustomerJobNumber', type: 'LIKE', value: search },
                      { field: 'Id', type: 'LIKE', value: search },
                      { field: 'Name', type: 'LIKE', value: search }
                  ]
                : [{ field: 'Name', type: 'LIKE', value: search }];
            filters.push({ field: '', type: 'OR', value: searchFilters });
        }

        if (status && status?.length > 0) {
            const statusFilters = status?.map(statusItem => ({
                field: 'Status',
                type: '==',
                value: statusItem
            }));
            filters.push({ field: '', type: 'OR', value: statusFilters });
        }

        if (startDate) {
            filters.push({ field: 'StartDateTime', type: '>=', value: new Date(new Date(startDate).setUTCHours(0, 0, 0, 0)) });
        }
        if (endDate) {
            filters.push({ field: 'EndDateTime', type: '<=', value: new Date(new Date(endDate).setUTCHours(23, 59, 59, 999)) });
        }

        return filters;
    }
}
