import { setAdminLoading } from '../actions/loaded.actions';
import { createReducer, on } from '@ngrx/store';

export interface AdminLoading {
    isAdminLoading: boolean;
}

export const initialLoadingState: AdminLoading = {
    isAdminLoading: false
};

export const loadedReducer = createReducer(
    initialLoadingState,
    on(setAdminLoading, (state, { isAdminLoaded }) => {
        return { ...state, isAdminLoading: isAdminLoaded };
    })
);
