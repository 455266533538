import { ItemOutCP } from '../../models/item-out-model';
import { sumOfArray } from '../../utils/sumOfArray.util';
import { itemsOutFeatureKey, ItemsOutState } from './../reducers/items-out.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const itemsOutSelector = createFeatureSelector<ItemsOutState>(itemsOutFeatureKey);

export const getAllItemsOut = createSelector(itemsOutSelector, (state: ItemsOutState) => state.items);

export const getPageNumberItemsOut = createSelector(itemsOutSelector, (state: ItemsOutState) => state.pageNumber);

export const getNoMoreRecordSelector = createSelector(itemsOutSelector, (state: ItemsOutState) => state.noMoreRecord);

const selectLineItemById = (lineItemId: string) => createSelector(getAllItemsOut, (lineItems: ItemOutCP[]) => lineItems.find(item => item.id === lineItemId));
export const isLineItemCalledOff = (lineItemId: string) =>
    createSelector(selectLineItemById(lineItemId), (lineItem: ItemOutCP | undefined) => {
        if (lineItem?.callOffQty && Array.isArray(lineItem.callOffQty) && lineItem.callOffQty.length > 0 && lineItem?.actualQuantity) {
            const callOffQty: number = sumOfArray(lineItem.callOffQty);
            return callOffQty === lineItem.actualQuantity ? true : false; // Return true if fully called off
        }
        return false;
    });
