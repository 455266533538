import { Component, ContentChildren, QueryList, AfterContentInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AccordionGroupComponent } from './accordion-group.component';

@Component({
    selector: 'por-accordion',
    template: `
        <div class="accordion" [ngClass]="style"><ng-content></ng-content></div>
    `,
    styleUrls: ['./accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent {
    @ContentChildren(AccordionGroupComponent)
    groups!: QueryList<AccordionGroupComponent>;
    @Input() style = '';
}
