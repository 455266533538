import { createAction, props } from '@ngrx/store';
import { ProductDetail } from '../../models/product-detail';
import { HttpErrorResponse } from '@angular/common/http';

export enum ProductActionTypes {
    LoadProduct = '[Product] Load Product',
    LoadProductSuccess = '[Product] Load Product Success',
    LoadProductFail = '[Product] Load Product Fail',
    ResetProduct = '[Product] Reset Product'
}

export const loadProduct = createAction(
    ProductActionTypes.LoadProduct,
    props<{ customerId: string; contractId: string; productId: string; stockId: string; columns: string[]; callback?: () => void }>()
);

export const loadProductSuccess = createAction(ProductActionTypes.LoadProductSuccess, props<{ productDetail: ProductDetail[] }>());

export const loadProductFail = createAction(ProductActionTypes.LoadProductFail, props<{ error: HttpErrorResponse }>());

export const resetProduct = createAction(ProductActionTypes.ResetProduct);
