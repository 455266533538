import { Component, Output, EventEmitter, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppMediatorService } from '../../../services/app-mediator.service';
@Component({
    selector: 'por-busy-indicator',
    templateUrl: './busy-indicator-component.component.html',
    styleUrls: ['./busy-indicator-component.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusyIndicatorComponentComponent implements OnInit {
    @Output() readonly selectedTemplate: EventEmitter<string> = new EventEmitter<string>();
    @Input() message!: string;

    constructor(private readonly translateService: TranslateService, private readonly appMediatorService: AppMediatorService) {}

    async ngOnInit() {
        this.translateService.use(this.appMediatorService.localStorageService.selectedContentLanguage);
    }
}
