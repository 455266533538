/**
 * An enumeration of supported payment types.
 * 1=CreditCard, 2=DebitCard, 3=GiftCard, 4=ACH, 5=Crypto
 */
export enum PaymentMethodEnums {
    CreditCard = 1,
    DebitCard = 2,
    GiftCard = 3,
    ACH = 4,
    Crypto = 5
}
export enum PaymentSourceEnums {
    QuickLink = 'QuickLink',
    ConsumerPortal = 'ConsumerPortal'
}
