import { ComponentsToLoad, SubComponentsToLoad } from '../../enums/components-to-load.enum';
import { setActiveTab, setSubActiveTab } from './../actions/active-tab.actions';
import { createReducer, on } from '@ngrx/store';

export interface ActiveTabState {
    activeTab: {
        parentTab: ComponentsToLoad;
        childTab: SubComponentsToLoad;
    };
}

export const initialActiveTabState: ActiveTabState = {
    activeTab: {
        parentTab: ComponentsToLoad.AccountSummary, // Default parent tab
        childTab: SubComponentsToLoad.Default // Default child tab
    }
};

export const activeTabReducer = createReducer(
    initialActiveTabState,
    on(setActiveTab, (state, { activeTab }) => ({
        ...state,
        activeTab: {
            parentTab: activeTab,
            childTab: SubComponentsToLoad.Default
        }
    })),
    on(setSubActiveTab, (state, { subActiveTab }) => ({
        ...state,
        activeTab: {
            parentTab: state.activeTab.parentTab,
            childTab: subActiveTab
        }
    }))
);
