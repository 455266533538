import { createReducer, on } from '@ngrx/store';
import { ConsumerPortalConfig } from '../../models';
import { setConfig } from '../actions/config.actions';
import { consumerPortalDefaults } from '../../../input-config/consumer-portal-config';

export interface ConfigState {
    config: ConsumerPortalConfig;
}

export const initialConfigState: ConfigState = {
    config: consumerPortalDefaults
};

export const configReducer = createReducer(
    initialConfigState,
    on(setConfig, (state: ConfigState, { config }: { config: ConsumerPortalConfig }) => {
        return { ...state, config: { ...config } };
    })
);
